import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { AccountFormComponent } from './account-form/account-form.component';
import { AccountComponent } from './account/account.component';
import { AccountsComponent } from './accounts/accounts.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyFormComponent } from './company-form/company-form.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ReportStatsGraphComponent } from './report-stats-graph/report-stats-graph.component';
import { PasswordResetComponent } from './reset-password/reset-password.component';
import { AccountsService } from './shared/accounts.service';
import { roleAuthGuard } from './shared/auth.guard';
import { CompanyService } from './shared/company.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    loadComponent: () => import('./full-layout/full-layout.component').then(m => m.FullLayoutComponent),
    children: [
      {
        path: 'dashboard',
        canActivate: [() => roleAuthGuard(['SopayoAdmin'])],
        component: DashboardComponent,
      },
      {
        path: 'accounts',
        canActivate: [() => roleAuthGuard(['SopayoAdmin'])],
        component: AccountsComponent,
        resolve: {
          accounts: (route: ActivatedRouteSnapshot) => inject(AccountsService).getAccounts(),
        },
      },
      {
        path: 'accounts/new',
        canActivate: [() => roleAuthGuard(['SopayoAdmin'])],
        component: AccountFormComponent,
      },
      {
        path: 'accounts/:id/edit',
        canActivate: [() => roleAuthGuard(['SopayoAdmin'])],
        component: AccountComponent,
        resolve: {
          groups: (route: ActivatedRouteSnapshot) => inject(AccountsService).getGroupsForAccount(route.params['id']),
        },
      },
      {
        path: 'companies',
        canActivate: [() => roleAuthGuard(['SopayoAdmin'])],
        component: CompaniesComponent,
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        resolve: {
          companies: (route: ActivatedRouteSnapshot) => inject(CompanyService).getCompanies({
            pageIndex: +(route.queryParamMap.get('pageIndex') ?? 0),
            pageSize: +(route.queryParamMap.get('pageSize') ?? 10),
          }),
        },
      },
      {
        path: 'companies/new',
        canActivate: [() => roleAuthGuard(['SopayoAdmin'])],
        component: CompanyFormComponent,
      },
      {
        path: 'companies/:id/edit',
        canActivate: [() => roleAuthGuard(['SopayoAdmin'])],
        component: CompanyFormComponent,
        resolve: {
          company: (route: ActivatedRouteSnapshot) => inject(CompanyService).getCompany(route.params['id']),
        },
      },
      {
        path: 'reports/stats',
        canActivate: [() => roleAuthGuard(['SopayoAdmin'])],
        component: ReportStatsGraphComponent,
      }
    ],
  },
  {
    path: '',
    loadComponent: () => import('./simple-layout/simple-layout.component').then(m => m.SimpleLayoutComponent),
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'logout',
        component: LogoutComponent,
      },
      {
        path: 'reset-password',
        component: PasswordResetComponent,
      },
    ],
  },
];
