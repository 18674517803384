import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';

export const authInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
  // getting idToken from the authService
  const idToken = inject(AuthService).idToken$.value;

  // clone the request and add the idToken as a header
  const clonedRequest = request.clone({
    setHeaders: {
      Authorization: `Bearer ${idToken}`,
    }
  });

  return next(clonedRequest);
}
