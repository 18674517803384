import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from '../shared/company.service';

@Component({
  selector: 'app-company-form',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatGridListModule,
    MatExpansionModule,
    ReactiveFormsModule,
  ],
  template: `
    <form [formGroup]="companyForm" (ngSubmit)="onSubmit()">

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title i18n>Company Information</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-grid-list cols="1" rowHeight="70px" gutterSize="16px">
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label i18n>Company</mat-label>
              <input matInput formControlName="name" required />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label i18n>Legal Form</mat-label>
              <mat-select formControlName="legalForm" required>
                <mat-option *ngFor="let legalForm of legalForms" [value]="legalForm">{{ legalForm }}</mat-option>
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title i18n>Address Information</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-grid-list cols="1" rowHeight="70px" gutterSize="16px">
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label i18n>Street</mat-label>
              <input matInput formControlName="street" required />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label i18n>Postal Code</mat-label>
              <input matInput formControlName="postalCode" required />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label i18n>City</mat-label>
              <input matInput formControlName="city" required />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title i18n>Tax Information</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-grid-list cols="1" rowHeight="70px" gutterSize="16px">
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label i18n>Tax ID</mat-label>
              <input matInput formControlName="taxId" required />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label i18n>Tax Number</mat-label>
              <input matInput formControlName="taxNumber" required />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>

      <button mat-raised-button color="primary" type="submit">Save</button>
    </form>
  `,
  styles: [`
    mat-expansion-panel {
      max-width: 800px;
    }
    mat-form-field {
      min-width: 300px;
      max-width: 500px;
    }
  `]
})
export class CompanyFormComponent implements OnInit {

  companyForm: FormGroup;

  legalForms = [
    'GmbH', // Gesellschaft mit beschränkter Haftung
    'UG', // Unternehmergesellschaft (haftungsbeschränkt)
    'AG', // Aktiengesellschaft
    'e.V.', // Eingetragener Verein
    'GbR', // Gesellschaft bürgerlichen Rechts
    'OHG', // Offene Handelsgesellschaft
    'KG', // Kommanditgesellschaft
    'GmbH & Co. KG', // GmbH & Co. KG
    'PartG', // Partnerschaftsgesellschaft
    // Add more legal forms as needed
  ];

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.companyForm = this.fb.group({
      name: ['', Validators.required],
      legalForm: ['', Validators.required],
      street: ['', Validators.required],
      postalCode: ['', Validators.required],
      city: ['', Validators.required],
      taxId: ['', Validators.required],
      taxNumber: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // Get the company data from the route
    const company = this.route.snapshot.data['company'] as Company;
    if (company) {
      this.companyForm.patchValue(company);
    }
  }

  onSubmit(): void {
    if (this.companyForm.valid) {
      // Save the company data, either by creating or updating
      // ...

      // Redirect to the CompaniesComponent
      this.router.navigate(['/companies']);
    }
  }
}
