import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { StatisticItem } from '../shared/statistics.service';

@Component({
  selector: 'app-report-statistic-item-table',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  template: `
    <mat-table [dataSource]="tableDataSource" matSort>
      <ng-container matColumnDef="createdat">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Date (UTC) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createdat | date: 'short' : 'UTC' }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="event">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Event </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.event}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="campaign">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Campaign </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.campaign}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="campaignpart">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Part </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.campaignpart}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="configid">
        <mat-header-cell *matHeaderCellDef> ConfigId </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.configid}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Country </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.country}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="impressions">
        <mat-header-cell *matHeaderCellDef> Impressions </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.impressions}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="uniques">
        <mat-header-cell *matHeaderCellDef> Uniques </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.uniques}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
  `,
  styles: [`
  `]
})
export class StatisticItemTableComponent implements OnInit, AfterViewInit {

  @Input()
  data: StatisticItem[] = [];

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  @ViewChild(MatSort)
  sort!: MatSort;

  tableDataSource = new MatTableDataSource<StatisticItem>();
  displayedColumns: string[] = ['createdat', 'event', 'campaign', 'campaignpart', /*'configid',*/ 'country', 'impressions', 'uniques'];

  ngOnInit(): void {
    this.tableDataSource.data = this.data;
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator; // attach paginator to the data source
    this.tableDataSource.sort = this.sort; // attach sort to the data source
  }
}
