import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { groupBy, map, mergeMap, reduce, shareReplay, toArray } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export interface StatisticItem {
  campaign: string;
  event: string;
  createdat: string;
  bucket: string;
  country: string;
  uniques: string;
  configid: string;
  partitionkey: string;
  month: string;
  year: string;
  uuid: string;
  impressions: string;
  hour: string;
  day: string;
  campaignpart: string;
}

export interface StatisticData {
  Count: number;
  Items: StatisticItem[];
}

export const aggregateStatisticsData = () => (source: Observable<StatisticItem[]>): Observable<StatisticItem[]> => source.pipe(
  // flatten the array of items
  mergeMap(items => items),
  // create unique group key to aggregate impressions and uniques for
  groupBy(item =>
    JSON.stringify({
      event: item.event,
      createdat: item.createdat,
      campaign: item.campaign,
    })
  ),
  // aggregate impressions and uniques for each group
  mergeMap(group => group.pipe(
    reduce((acc, cur) => ({
      ...cur,
      impressions: (parseInt(acc.impressions ?? '0') + parseInt(cur.impressions ?? '0')).toString(),
      uniques: (parseInt(acc.uniques ?? '0') + parseInt(cur.uniques ?? '0')).toString()
    }), {} as StatisticItem)
  )),
  // convert back to an array
  toArray(),
);

/** converts a number into a string with leading zeros */
const leadingZero = (num: number, digits: number = 2): string => num.toString().padStart(digits, '0');
/** converts a date into a string with the format YYYY-MM-DD */
const dateToString = (date: Date): string => `${date.getFullYear()}-${leadingZero(date.getMonth() + 1)}-${leadingZero(date.getDate())}`;

@Injectable({
  providedIn: 'root'
})
export class StatisticService {

  constructor(private http: HttpClient) { }

  getData(param: { start?: Date, end?: Date, configId?: string }) {
    const NOW = new Date();
    return this.http.post<StatisticData>(`${environment.api.url}/statistic`, {
      command: 'alldata',
      dateFrom: dateToString(param.start ?? NOW),
      dateUntil: dateToString(param.end ?? NOW),
      configId: param.configId ?? '',
    }).pipe(
      // extract only the 'items' array from the response
      map(data => data.Items),
      // optionally aggregate the data - should be done on the backend in later iterations
      aggregateStatisticsData(),
      // share the data with all subscribers
      shareReplay(1),
      // log the raw data for debugging
      // tap(data => console.log('Raw data:', data)),
    );
  }

  getCampaigns(param: { start?: Date, end?: Date }) {
    const NOW = new Date();
    return this.http.post<StatisticData>(`${environment.api.url}/statistic`, {
      command: 'alldata',
      dateFrom: dateToString(param.start ?? NOW),
      dateUntil: dateToString(param.end ?? NOW),
      groupBy: 'campaign',
    }).pipe(
      map(data => data.Items?.map(item => item.campaign)),
      shareReplay(1),
    );
  }

  getConfigIds(param: { start?: Date, end?: Date }) {
    const NOW = new Date();
    return this.http.post<StatisticData>(`${environment.api.url}/statistic`, {
      command: 'alldata',
      dateFrom: dateToString(param.start ?? NOW),
      dateUntil: dateToString(param.end ?? NOW),
      groupBy: 'configid',
    }).pipe(
      map(data => data.Items?.map(item => item.configid)),
      shareReplay(1),
    );
  }
}
