import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from '../shared/accounts.service';

@Component({
  selector: 'app-account-form',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  template: `
    <form [formGroup]="accountForm" (ngSubmit)="onSubmit()">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title i18n>Acount Basics</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-grid-list cols="1" rowHeight="70px" gutterSize="16px">
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label i18n>Username</mat-label>
              <input matInput formControlName="username" required />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field appearance="fill">
              <mat-label i18n>Email</mat-label>
              <input matInput formControlName="email" required />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-expansion-panel>

      <button mat-raised-button color="primary" type="submit">Save</button>
    </form>
  `,
  styles: [`
    mat-expansion-panel {
      max-width: 800px;
    }
    mat-form-field {
      min-width: 300px;
      max-width: 500px;
    }
  `],
})
export class AccountFormComponent implements OnInit {

  accountForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private accountsService: AccountsService,
  ) {
    this.accountForm = this.fb.group({
      username: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      // password: ['', Validators.required],
      // passwordConfirm: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const account = this.route.snapshot.data['account'];
    if (account) {
      this.accountForm.patchValue(account);
    }
  }

  onSubmit(): void {
    if (!this.accountForm.valid) return;

    this.accountsService.createAccount(this.accountForm.getRawValue()).subscribe({
      next: () => {
        console.log('Account created');
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
