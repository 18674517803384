import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface Company {
  id: string;
  name: string;
  legalForm: string;
  street: string;
  postalCode: string;
  city: string;
  taxId: string;
  taxNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor() { }

  dummyData = [
    { id: '1', name: 'Company A', legalForm: 'GmbH', street: 'Example Street 1', postalCode: '12345', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '2', name: 'Company B', legalForm: 'GmbH', street: 'Example Street 2', postalCode: '67890', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '3', name: 'Company C', legalForm: 'GmbH', street: 'Example Street 3', postalCode: '12345', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '4', name: 'Company D', legalForm: 'GmbH', street: 'Example Street 4', postalCode: '67890', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '5', name: 'Company E', legalForm: 'GmbH', street: 'Example Street 5', postalCode: '12345', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '6', name: 'Company F', legalForm: 'GmbH', street: 'Example Street 6', postalCode: '67890', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '7', name: 'Company G', legalForm: 'GmbH', street: 'Example Street 7', postalCode: '12345', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '8', name: 'Company H', legalForm: 'GmbH', street: 'Example Street 8', postalCode: '67890', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '9', name: 'Company I', legalForm: 'GmbH', street: 'Example Street 9', postalCode: '12345', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '10', name: 'Company J', legalForm: 'GmbH', street: 'Example Street 10', postalCode: '67890', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '11', name: 'Company K', legalForm: 'GmbH', street: 'Example Street 11', postalCode: '12345', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '12', name: 'Company L', legalForm: 'GmbH', street: 'Example Street 12', postalCode: '67890', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '13', name: 'Company M', legalForm: 'GmbH', street: 'Example Street 13', postalCode: '12345', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '14', name: 'Company N', legalForm: 'GmbH', street: 'Example Street 14', postalCode: '67890', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '15', name: 'Company O', legalForm: 'GmbH', street: 'Example Street 15', postalCode: '12345', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '16', name: 'Company P', legalForm: 'GmbH', street: 'Example Street 16', postalCode: '67890', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '17', name: 'Company Q', legalForm: 'GmbH', street: 'Example Street 17', postalCode: '12345', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '18', name: 'Company R', legalForm: 'GmbH', street: 'Example Street 18', postalCode: '67890', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '19', name: 'Company S', legalForm: 'GmbH', street: 'Example Street 19', postalCode: '12345', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '20', name: 'Company T', legalForm: 'GmbH', street: 'Example Street 20', postalCode: '67890', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '21', name: 'Company U', legalForm: 'GmbH', street: 'Example Street 21', postalCode: '12345', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    { id: '22', name: 'Company V', legalForm: 'GmbH', street: 'Example Street 22', postalCode: '67890', city: 'Berlin', taxId: 'DE123456789', taxNumber: '12/345/67890' },
    // Add more dummy data
  ];

  getCompanies({ pageIndex, pageSize }: { pageIndex: number, pageSize: number }): Observable<{ data: Company[], totalItems: number, pageIndex: number, pageSize: number }> {
    return of({
      data: this.dummyData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize),
      totalItems: this.dummyData.length,
      pageIndex,
      pageSize,
    });
  }

  getCompany(id: string): Observable<Company | undefined> {
    return of(this.dummyData.find(company => company.id === id));
  }
}
