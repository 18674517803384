import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { AuthService, Credentials } from '../shared/auth.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterLink,
  ],
  template: `
    <mat-card>
      <mat-card-header>
        <h1>Log In</h1>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
          <mat-error *ngIf="!loginValid">The username and password were not recognized</mat-error>
          <mat-form-field appearance="fill">
            <mat-label>Username</mat-label>
            <input matInput placeholder="Email" formControlName="username" required>
            <mat-icon matPrefix>alternate_email</mat-icon>
            <mat-error>Please provide a valid email address</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
            <input matInput type="password" placeholder="Password" formControlName="password" required>
            <mat-icon matPrefix>password</mat-icon>
            <mat-error>Please provide a valid password</mat-error>
          </mat-form-field>
          <button mat-raised-button color="primary" [disabled]="!formGroup.dirty || formGroup.invalid">Login</button>
        </form>
      </mat-card-content>
      <mat-card-actions>
        <a routerLink="/reset-password" class="reset">reset password</a>
      </mat-card-actions>
    </mat-card>
  `,
  styles: [`
    :host {
      display: flex;
      height: 100vh;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
    mat-card {
      flex: 1;
      min-width: 290px;
      max-width: 400px;
      padding: 10px;
      text-align: center;
    }
    mat-form-field {
      display: block;
      margin: 10px 0;
    }
    .reset {
      flex: 1;
      text-decoration: none;
      font-size: 12px;
      &:visited { color: inherit; }
    }
  `]
})
export class LoginComponent {

  loginValid = true;

  formGroup = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly fb: NonNullableFormBuilder,
  ) { }

  onSubmit(): void {
    if (this.formGroup.invalid) {
      return;
    }

    const credentials: Credentials = this.formGroup.getRawValue();

    this.authService.authenticate(credentials).subscribe({
      next: (loginValid) => {
        this.loginValid = loginValid;
        if (loginValid) {
          this.router.navigate(['/']);
        }
      },
      error: () => {
        this.loginValid = false;
      },
    });
  }
}
