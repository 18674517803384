import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export interface Credentials {
  username: string;
  password: string;
}

export interface UserInfo {
  username: string;
  groups: string[];
}

const STORAGE_KEY = 'idToken';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public readonly idToken$: BehaviorSubject<string>;
  public readonly userInfo$ = new BehaviorSubject<UserInfo | undefined>(undefined);

  constructor(
    private readonly http: HttpClient,
  ) {
    const storage: Storage = window.sessionStorage;

    // Create a behavior subject to track the storage entry for the id token
    this.idToken$ = new BehaviorSubject<string>(
      storage.getItem(STORAGE_KEY) ?? '',
    );

    // Subscribe to changes to the storage entry and update the behavior subject
    window.addEventListener('storage', (event) => {
      // This event listener will be triggered when changes to the storage occur from other tabs/windows within the same origin.
      // However, when a change to the storage occurs within the same tab, this event listener will not be triggered.
      if (event.key === STORAGE_KEY) {
        // When the storage entry changes, update the behavior subject with the new value.
        this.idToken$.next(event.newValue ?? '');
      }
    });

    // Subscribe to changes to the behavior subject and update the storage entry
    this.idToken$.subscribe(idToken => {
      // If the id token is empty, clear the storage entry and the user info
      if (!idToken) {
        storage.removeItem(STORAGE_KEY);
        this.userInfo$.next({
          username: '',
          groups: [],
        });
        return;
      }

      // Decode the id token to get the user info
      const payload = JSON.parse(atob(idToken.split('.')[1]));

      // Check if the token has expired and if so, clear the id token
      if (payload.exp * 1000 < Date.now()) {
        this.idToken$.next('');
        return;
      }

      // Update the user info behavior subject with the user info from the id token
      this.userInfo$.next({
        username: payload['cognito:username'] ?? '',
        groups: payload['cognito:groups'] ?? [],
      });

      // Update the storage entry with the id token
      storage.setItem(STORAGE_KEY, idToken);
    });
  }

  public authenticate(credentials: Credentials): Observable<boolean> {
    return this.http.post<{ idToken: string }>(`${environment.api.url}/account/login`, credentials)
      .pipe(
        map(response => {
          this.idToken$.next(response?.idToken ?? '');
          return !!response?.idToken;
        }),
      );
  }

  public resetPassword(credentials: Credentials, verificationCode?: string): Observable<void> {
    return this.http.post<void>(`${environment.api.url}/account/reset-password`, {
      ...credentials,
      verificationCode,
    });
  }

  public logout(): Observable<boolean> {
    this.idToken$.next('');
    return of(true);
  }
}
