import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Company } from '../shared/company.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
  ],
  template: `
    <div class="companies-container">
      <button mat-raised-button color="primary" (click)="createCompany()">New Company</button>
      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef i18n>Firma</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="legalForm">
          <mat-header-cell *matHeaderCellDef i18n>Rechtsform</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.legalForm}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="street">
          <mat-header-cell *matHeaderCellDef i18n>Straße</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.street}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="postalCode">
          <mat-header-cell *matHeaderCellDef i18n>PLZ</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.postalCode}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="city">
          <mat-header-cell *matHeaderCellDef i18n>Ort</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.city}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="taxId">
          <mat-header-cell *matHeaderCellDef i18n>Steuernummer</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.taxId}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="taxNumber">
          <mat-header-cell *matHeaderCellDef i18n>Umsatzsteuer-Identifikationsnummer</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.taxNumber}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="edit">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button color="primary" (click)="editCompany(element)">
              <mat-icon>edit</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator
        [length]="totalItems"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        [pageSizeOptions]="[10, 20, 50]"
        (page)="handlePageEvent($event)"
        showFirstLastButtons
      />
    </div>
  `,
  styles: [
  ]
})
export class CompaniesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = ['name', 'legalForm', 'street', 'postalCode', 'city', 'edit'];

  dataSource = new MatTableDataSource<Company>([]);
  totalItems = 0;
  pageIndex = 0;
  pageSize = 5;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const routeData$ = this.route.data as Observable<{ companies: { data: Company[], totalItems: number, pageIndex: number, pageSize: number } }>;
    routeData$.subscribe(({ companies }) => {
      this.dataSource.data = companies.data;
      this.totalItems = companies.totalItems;
      this.pageIndex = companies.pageIndex;
      this.pageSize = companies.pageSize;
    });
  }

  handlePageEvent({ pageIndex, pageSize }: PageEvent): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { pageIndex, pageSize },
      queryParamsHandling: 'merge',
    });
  }

  createCompany() {
    this.router.navigate(['/companies/new']);
  }

  editCompany(company: any) {
    this.router.navigate(['/companies', company.id, 'edit']);
  }
}
