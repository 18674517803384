import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { AuthService, Credentials } from '../shared/auth.service';

@Component({
  selector: 'app-password-reset',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  template: `
    <mat-card>
      <mat-card-header>
        <h1>Password Reset</h1>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="emailFormGroup" (ngSubmit)="onSubmit()" *ngIf="!isCodeSent">
          <mat-form-field appearance="fill">
            <mat-label>Username</mat-label>
            <input matInput placeholder="Email" formControlName="username" required>
            <mat-icon matPrefix>alternate_email</mat-icon>
            <mat-error>Please provide a valid email address</mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>New Password</mat-label>
            <input matInput type="password" placeholder="Password" formControlName="password" required>
            <mat-icon matPrefix>password</mat-icon>
            <mat-error>Please provide a valid password</mat-error>
          </mat-form-field>
          <button mat-raised-button color="primary" [disabled]="!emailFormGroup.dirty || emailFormGroup.invalid">Submit</button>
        </form>
        <form [formGroup]="codeFormGroup" (ngSubmit)="onSubmit()" *ngIf="isCodeSent" >
          <mat-form-field appearance="fill">
            <mat-label>Verification Code</mat-label>
            <input matInput type="text" placeholder="Verification Code" formControlName="verificationCode" required>
            <mat-error>Please provide a valid verification code</mat-error>
          </mat-form-field>
          <button mat-raised-button color="primary" [disabled]="!emailFormGroup.dirty || emailFormGroup.invalid">Submit</button>
        </form>
      </mat-card-content>
    </mat-card>
  `,
  styles: [`
    :host {
      display: flex;
      height: 100vh;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
    mat-card {
      flex: 1;
      min-width: 290px;
      max-width: 400px;
      padding: 10px;
      text-align: center;
    }
    mat-form-field {
      display: block;
      margin: 10px 0;
    }
  `]
})
export class PasswordResetComponent {

  isCodeSent = false;

  emailFormGroup = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', Validators.required],
  });

  codeFormGroup = this.fb.group({
    verificationCode: ['', Validators.required],
  });

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly fb: NonNullableFormBuilder,
  ) { }

  onSubmit(): void {
    if (this.emailFormGroup.invalid) {
      return;
    }
    if (this.isCodeSent && this.codeFormGroup.invalid) {
      return;
    }

    const credentials: Credentials = this.emailFormGroup.getRawValue();
    const verificationCode = this.codeFormGroup.get('verificationCode')?.value;

    // Call the backend API to verify the verification code
    this.authService.resetPassword(credentials, verificationCode).subscribe({
      next: () => {
        if (!verificationCode) {
          // when verification code is not provided, backend will send the verification code
          this.isCodeSent = true;
        } else {
          // Verification code is valid, navigate to the password reset page
          this.router.navigate(['/login']);
        }
      },
      error: () => {
        // Handle error response from the backend
        this.codeFormGroup.get('verificationCode')?.setErrors({ invalidCode: true });
      },
    });
  }
}
