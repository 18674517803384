import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface Account {
  userName: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  createAccount(account: Account) {
    return this.http.post<void>(`${environment.api.url}/accounts`, account);
  }

  getAccounts() {
    return this.http.get<void>(`${environment.api.url}/accounts`);
  }

  getGroupsForAccount(id: string) {
    return this.http.get<void>(`${environment.api.url}/accounts/${id}/groups`);
  }
}
