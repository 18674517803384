import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { LOCALE_ID, importProvidersFrom, isDevMode } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ApplicationConfig } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { routes } from './app.routes';
import { authInterceptorFn } from './shared/auth.interceptor';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

// TODO: remove when updating to angular 16
const provideServiceWorker = (script: string, options?: SwRegistrationOptions) => importProvidersFrom(ServiceWorkerModule.register(script, options));

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'yyyy-MM-dd HH:mm:ss' } },
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([
        authInterceptorFn,
      ]),
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
    }),
  ],
};
