import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-account',
  standalone: true,
  imports: [CommonModule],
  template: `
    <p>
      account works!
    </p>
  `,
  styles: [
  ]
})
export class AccountComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const routeData$ = this.route.data as Observable<{ groups: any }>;
    routeData$.subscribe(({ groups }) => {
      console.log(groups);
    });
  }
}
