import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attributes',
  standalone: true,
})
export class AttributesPipe implements PipeTransform {

  transform(value: Array<{ Name: string; Value: string }>) {
    return value.reduce<Record<string, string>>((acc, cur) => {
      acc[cur.Name] = cur.Value;
      return acc;
    }, {});
  }
}
