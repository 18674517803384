import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { LegendPosition, NgxChartsModule } from "@swimlane/ngx-charts";
import { Observable, combineLatest, filter, from, groupBy, mergeMap, of, reduce, shareReplay, startWith, switchMap, tap, toArray } from 'rxjs';
import * as XLSX from 'xlsx';
import { StatisticItem, StatisticService } from '../shared/statistics.service';
import { StatisticItemTableComponent } from './statistic-item-table.component';

@Component({
  selector: 'app-report-stats-graph',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    NgxChartsModule,
    StatisticItemTableComponent,
  ],
  template: `
    <mat-card>
      <mat-card-content>
        <form class="my-form">
          <mat-form-field appearance="outline" class="my-field">
            <mat-label>Select date range</mat-label>
            <mat-date-range-input
              [formGroup]="dateFilter"
              [rangePicker]="dateFilterPicker">
              <input matStartDate placeholder="Start date" formControlName="start">
              <input matEndDate placeholder="End date" formControlName="end">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="dateFilterPicker"></mat-datepicker-toggle>
            <mat-date-range-picker #dateFilterPicker startView="month"></mat-date-range-picker>
          </mat-form-field>

          <mat-form-field appearance="outline" class="my-field">
            <mat-label>Config Filter</mat-label>
            <mat-select [formControl]="configidFilter">
              <mat-option *ngFor="let configid of configids$ | async" [value]="configid">
                {{ configid }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="my-field">
            <mat-label>Campaign Filter</mat-label>
            <mat-select [formControl]="campaignFilter">
              <mat-option *ngFor="let campaign of campaigns$ | async" [value]="campaign">
                {{ campaign }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="chartData$ | async as data">
      <mat-card-header>
        <mat-card-title>Breakdown per Event</mat-card-title>
        <mat-card-subtitle>campaign "{{campaignFilter.value}}"</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div>
          <ngx-charts-line-chart
            [results]="data"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="true"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            legendTitle=""
            [legendPosition]="legendPosition"
          />
        </div>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="pieData$ | async as data">
      <mat-card-header>
        <mat-card-title>Breakdown per Country</mat-card-title>
        <mat-card-subtitle>campaign "{{campaignFilter.value}}"</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div>
          <ngx-charts-pie-chart
            [results]="data"
            [view]="[500,300]"
            [legend]="true"
            [animations]="true"
          />
        </div>
      </mat-card-content>
      <mat-card-actions>
      </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="rawData$ | async as data">
      <mat-card-header>
        <mat-card-title>Data as table</mat-card-title>
        <mat-card-subtitle>configId "{{configidFilter.value ? configidFilter.value : 'all'}}" from {{dateFilter.value.start | date }} to {{dateFilter.value.end | date}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-report-statistic-item-table [data]="data"/>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-button mat-raised-button (click)="downloadExcel('report', data)">Download Excel</button>
      </mat-card-actions>
    </mat-card>
  `,
  styles: [`
    .my-form { min-width: 150px; max-width: 500px; width: 100%; }
    .my-field { width: 100%; }
  `]
})

//FORMATTING DATE: https://stackoverflow.com/questions/44304781/show-date-values-on-x-axis-in-locale-depending-format-ngx-charts-d3

export class ReportStatsGraphComponent implements OnInit {

  dateFilter = new FormGroup({
    start: new FormControl(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1), { nonNullable: true }),
    end: new FormControl(new Date(), { nonNullable: true }),
  });

  configidFilter = new FormControl('', { nonNullable: true });
  campaignFilter = new FormControl('', { nonNullable: true });

  xAxisLabel = 'Date';
  yAxisLabel = 'Impressions';
  legendPosition = LegendPosition.Below;

  rawData$: Observable<StatisticItem[]> = of();
  configids$: Observable<string[]> = of();
  campaigns$: Observable<string[]> = of(['empty']);

  constructor(
    private statisticService: StatisticService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.rawData$ = combineLatest([
      this.dateFilter.valueChanges.pipe(startWith(this.dateFilter.value)),
      this.configidFilter.valueChanges,
    ]).pipe(
      // for each change of the date filter or configId filter, fetch the data from the server
      switchMap(([{ start, end }, configId]) => this.statisticService.getData({ start, end, configId })),
      // share the data with all subscribers
      shareReplay(1),
    );

    this.configids$ = this.dateFilter.valueChanges.pipe(
      startWith(this.dateFilter.value),
      switchMap(({ start, end }) => this.statisticService.getConfigIds({ start, end })),
    );

    this.campaigns$ = this.dateFilter.valueChanges.pipe(
      startWith(this.dateFilter.value),
      switchMap(({ start, end }) => this.statisticService.getCampaigns({ start, end })),
    );
  }

  chartData$ = this.campaignFilter.valueChanges.pipe(
    switchMap(campaign => this.rawData$.pipe(
      // transform the array of StatisticItems into a stream of items
      switchMap(items => from(items).pipe(
        // filter by campaign
        filter(item => campaign === null || item.campaign === campaign),
        // group by event
        groupBy(item => item.event),
        // Process each group
        mergeMap(group => group.pipe(
          reduce((acc, cur) => {
            acc.series.push({
              name: new Date(Date.parse(cur.createdat)),
              value: +(cur.impressions || '0'),
            });
            return acc;
          }, { name: group.key, series: [] } as { name: string, series: { name: Date, value: number }[] }),
        )),
        toArray(),
      )),
    )),
    tap(data => console.log('line chart by event data:', data)),
  );

  pieData$ = this.campaignFilter.valueChanges.pipe(
    switchMap(campaign => this.rawData$.pipe(
      // transform the array of StatisticItems into a stream of items
      switchMap(items => from(items).pipe(
        // filter by campaign
        filter(item => campaign === null || item.campaign === campaign),
        // group by country
        groupBy(item => item.country),
        // Process each group
        mergeMap(group => group.pipe(
          reduce((acc, cur) => {
            acc.value += +(cur.impressions || '0');
            return acc;
          }, { name: group.key, value: 0 }),
        )),
        toArray(),
      )),
    )),
    tap(data => console.log('pie chart by country data:', data)),
  );

  downloadExcel(name: string, data: any) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob: Blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveBlobAs(`${name}.xlsx`, dataBlob);
  }
}

/**
 * Saves a blob as a file with the given name
 *
 * @param fileName a given file name
 * @param blob a blob to save
 */
const saveBlobAs = (fileName: string, blob: Blob) => {
  // create an data url from the blob
  const url = URL.createObjectURL(blob);
  // create a file download
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  // free up memory
  URL.revokeObjectURL(url);
}
