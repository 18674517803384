import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AttributesPipe } from '../shared/attributes.pipe';
import { StringifyPipe } from '../shared/stringify.pipe';

@Component({
  selector: 'app-accounts',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    StringifyPipe,
    AttributesPipe,
  ],
  template: `
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Username</th>
        <td mat-cell *matCellDef="let user">{{ user.Username }}</td>
      </ng-container>

      <ng-container matColumnDef="attributes">
        <th mat-header-cell *matHeaderCellDef>Attributes</th>
        <td mat-cell *matCellDef="let user"><pre>{{ user.Attributes | attributes | stringify }}</pre></td>
      </ng-container>

      <ng-container matColumnDef="creation">
        <th mat-header-cell *matHeaderCellDef>Creation</th>
        <td mat-cell *matCellDef="let user">{{ user.UserCreateDate | date }}</td>
      </ng-container>

      <ng-container matColumnDef="modified">
        <th mat-header-cell *matHeaderCellDef>LastModified</th>
        <td mat-cell *matCellDef="let user">{{ user.UserLastModifiedDate | date }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let user">{{ user.UserStatus}}</td>
      </ng-container>

      <ng-container matColumnDef="enabled">
        <th mat-header-cell *matHeaderCellDef>Enabled</th>
        <td mat-cell *matCellDef="let user">{{ user.Enabled }}</td>
      </ng-container>

      <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let user">
            <button mat-icon-button color="primary" (click)="editAccount(user)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let user; columns: displayedColumns"></tr>
    </table>
  `,
  styles: [
  ]
})
export class AccountsComponent implements OnInit {

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['username', 'attributes', 'creation', 'modified', 'status', 'enabled', 'edit'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    const routeData$ = this.route.data as Observable<{ accounts: any }>;
    routeData$.subscribe(({ accounts }) => {
      console.log(accounts);
      this.dataSource.data = accounts.users
    });
  }

  editAccount(account: any) {
    this.router.navigate([account.Username, 'edit'], { relativeTo: this.route });
  }
}
