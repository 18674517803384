import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  template: `
    <p>
      dashboard works!
    </p>
  `,
  styles: [
  ]
})
export class DashboardComponent {

}
