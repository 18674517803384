import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

export const roleAuthGuard = (roles: string[]): Observable<boolean> => {
  // Inject the router and the auth service
  const router = inject(Router);
  const authService = inject(AuthService)
  // check if the user has the required role
  return authService.userInfo$
    .pipe(
      map(userInfo => roles.some(role => userInfo?.groups?.includes(role))),
      tap(allowed => !allowed ? router.navigate(['/login']) : null),
    );
}
